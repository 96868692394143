import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "../css/facilities.css"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { StaticImage } from "gatsby-plugin-image"

export default function UnitedPage(props) {
  return (
    <Layout>
      <Seo title="United in the West" />
      <div className="contentBox">
        <div className="mobile-back-bttn">
          <AniLink cover to="/facilities">
            Back to Facilities
          </AniLink>
        </div>
        <h1 className="facility-title">United in the West</h1>
        <div className="facility-container">
          <div className="facility-front-picture-container">
            <StaticImage
              src="../images/facilities/united/united-front1.jpg"
              alt="front yard"
              placeholder="blurred"
            />
          </div>
          <div className="facility-details-container">
            <div className="facility-detail-row">
              <p className="detail-title">EST</p>
              <p className="detail">2016</p>
            </div>
            <div className="facility-detail-row">
              <p className="detail-title">License #</p>
              <p className="detail">157206793</p>
            </div>
            <div className="facility-detail-row">
              <p className="detail-title">Description</p>
              <p className="detail">
                Negotiated rate RFP Community Placement Plan (CPP) project
                (Level 4I Specialized). This program is designed to operate as
                an Adult Residential Facility (ARF), (all male) ranging from 18
                to 59 years of age wild to moderate intellectual disabilities
                and co-occurring mental health diagnosis.
              </p>
            </div>
            <div className="facility-detail-row">
              <p className="detail-title">Services</p>
              <p className="detail">
                24 hour supervision, Enriched staff ratio, Private rooms,
                Window/door chimes, Egress Alert System, Supportive training,
                Emergency response training, Modified environment, Crisis
                Prevention Intervention, Trained/experienced staff, Supervision
                by a Licensed Psychiatric Technician
              </p>
            </div>
            <div className="facility-detail-row">
              <p className="detail-title">Specialization</p>
              <p className="detail">
                Planning and implementing treatment to developmentally disabled
                individuals who currently are or have a history of mental health
                problems, mal-adaptive behaviors such as inappropriate sexual
                behaviors, physical and verbal aggression, property destruction,
                compulsive eating, special diets, PICA, and Polydipsia.
              </p>
            </div>
          </div>
        </div>

        <h2 className="pictures-title">Facility Pictures</h2>
        <div className="masonry">
          <div className="item">
            <StaticImage
              src="../images/facilities/united/united-back1.jpg"
              alt="backyard"
              placeholder="blurred"
              width={400}
              height={600}
            />
          </div>
          <div className="item">
            <StaticImage
              src="../images/facilities/united/united-back2.jpg"
              alt="backyard"
              placeholder="blurred"
              width={400}
            />
          </div>
          <div className="item">
            <StaticImage
              src="../images/facilities/united/united-kitchen1.jpg"
              alt="kitchen"
              placeholder="blurred"
              width={500}
              height={600}
            />
          </div>
          <div className="item">
            <StaticImage
              src="../images/facilities/united/united-living1.jpg"
              alt="living area"
              placeholder="blurred"
              width={400}
              height={500}
            />
          </div>
          <div className="item">
            <StaticImage
              src="../images/facilities/united/united-living3.jpg"
              alt="living area"
              placeholder="blurred"
              width={400}
            />
          </div>
          <div className="item">
            <StaticImage
              src="../images/facilities/united/united-living4.jpg"
              alt="living area"
              placeholder="blurred"
              width={400}
              height={600}
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}
